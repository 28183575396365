import * as Utils from '@js/modules/utils';

import api from '@js/middlewares/api';

import History from '@js/modules/history';

import * as ActionTypes from '@js/constants/actionTypes';

import {
    spySearchResults
} from './metricsActions';

// Autocomplete
export const fetchAutocomplete = (autocompleteParams) => ({
    actionType: ActionTypes.SEARCH_AUTOCOMPLETE,
    fetchAPI: () => api.get('/api/search/autocomplete', {
        force_locale: window.locale,
        search: autocompleteParams
    }),
    payload: {
        query: autocompleteParams.query
    }
});

// Search history
const _saveHistory = (searchState, searchData) => {
    if (!searchData) {
        const currentState = History.getPreviousState('globalSearchData');

        if (currentState) {
            searchData = currentState;
        } else {
            return;
        }
    }

    let {
        query,
        location,
        ...searchDataParams
    } = searchData;

    searchDataParams = {
        rideable: null,
        trade: null,
        distance: null,
        distance_range: null,
        duration: null,
        duration_range: null,
        price: null,
        price_range: null,
        ...searchDataParams
    };
    const {
        text,
        ...locationParams
    } = searchData.location || {};
    const _URLParams = {
        query: searchData.query,
        location: locationParams,
        ...searchDataParams
    };

    History.saveCurrentState(
        {
            globalSearchData: searchData
        },
        _URLParams
    );
};

// Search
const initSearch = () => ({
    type: ActionTypes.SEARCH_FETCH_INIT,
    isSearching: true
});
const failSearch = () => ({
    type: ActionTypes.SEARCH_FETCH_ERROR,
    isSearching: false
});
const receiveSearch = (searchParams, json, options = {}) => ({
    type: ActionTypes.SEARCH_FETCH_SUCCESS,
    isSearching: false,
    isOverview: options.isOverview,
    isMapSearch: options.isMapSearch,
    searchParams: searchParams,
    isArea: searchParams.isArea,
    query: searchParams.query,
    locationPlace: searchParams.location && searchParams.location.place_name, // or location.text
    locationBounds: (searchParams.location && searchParams.location.bbox) || [],
    locationCenter: (searchParams.location && searchParams.location.center) || [],
    selectedTypes: searchParams.selectedTypes,
    aggregations: json.aggregations || {},
    suggestions: json.suggestions || {},
    totalCount: json.totalCount || {},
    totalPages: json.totalPages || {},
    rides: json.rides || [],
    shops: json.shops || [],
    products: json.products || [],
    rideFilters: options.filterType === 'ride' && options.filters,
    shopFilters: options.filterType === 'shop' && options.filters,
    productFilters: options.filterType === 'product' && options.filters
});

const _parseSearchParameters = (searchData, searchState, saveHistory) => {
    const params = {
        ...Utils.decodeObject(searchData)
    };

    if (searchData.city && Array.isArray(searchData.coordinates)) {
        // Search for a city with coordinates (breadcrumb)
        const city = decodeURIComponent(searchData.city);
        params.location = {
            city: city,
            text: city,
            place_name: city,
            center: [searchData.coordinates[0], searchData.coordinates[1]]
        };

        if (!params.selectedTypes) {
            params.selectedTypes = ['ride', 'shop']; // For the moment exclude product
        }
        // params.query = '*';
        params.city = decodeURIComponent(params.city);
    } else if (Utils.is()
        .isString(searchData.location)) {
        params.location = Utils.compact({
            text: searchData.location.replace(/\+/g, ' '),
            place_name: searchData.location.replace(/\+/g, ' '),
            center: searchData.center,
            bbox: searchData.bbox
        });

        params.query = searchData.query;
    } else if (searchData.region) {
        params.isArea = true;

        const region = decodeURIComponent(searchData.region);
        params.location = {
            city: region,
            text: region,
            place_name: region,
            center: [searchData.coordinates[0], searchData.coordinates[1]]
        };
    } else if (searchData.department) {
        params.isArea = true;

        const department = decodeURIComponent(searchData.department);
        params.location = {
            city: department,
            text: department,
            place_name: department,
            center: [searchData.coordinates[0], searchData.coordinates[1]]
        };
    } else if (searchData.location) {
        params.location = {
            center: searchData.location.center,
            bbox: searchData.location.bbox,
            place_type: searchData.location.placeType,
            text: searchData.location.value
        };
    }

    // params.order = params.order || 'rank_desc';

    params.selectedTypes ||= searchState.selectedTypes;

    if (saveHistory) {
        _saveHistory(searchState, params);
    }

    const {
        city,
        center,
        bbox,
        coordinates,
        ...otherParams
    } = params;

    return otherParams;
};

const performSearch = (searchParams, options = {}) => (dispatch) => {
    dispatch(initSearch());

    return api
        .get('/api/search', {
            force_locale: window.locale,
            search: searchParams
        })
        .request
        .then((response) => {
            if (!response || response.errors) {
                return dispatch(failSearch());
            } else {
                spySearchResults(searchParams, response);

                return dispatch(receiveSearch(searchParams, response, options));
            }
        });
};

export const fetchSearch = (searchData, saveHistory = true) => (dispatch, getState) => {
    if (Utils.isEmpty(searchData)) {
        return;
    }

    const searchParams = _parseSearchParameters(searchData, getState().searchState, saveHistory);

    return dispatch(performSearch(searchParams, {
        isOverview: searchData.isOverview
    }));
};

export const filterSearch = (filters, filterOptions) => (dispatch, getState) => {
    const searchParams = {
        ...getState().searchState.searchParams,
        ...filters,
    };

    _saveHistory(getState().searchState, searchParams);

    return dispatch(performSearch(searchParams, {filters, ...filterOptions}));
};

// For now, display all elements on map and fetch only on user input search
// export const locationSearch = (center, bounds, options = {}) => (dispatch, getState) => {
//     let searchParams = {
//         ...getState().searchState.searchParams,
//         ...{
//             location: {
//                 center,
//                 bbox: bounds
//             }
//         }
//     };
//
//     if (options) {
//         searchParams = {
//             ...searchParams,
//             ...options
//         };
//     }
//
//     _saveHistory(getState().searchState, searchParams);
//
//     return dispatch(performSearch(searchParams, options));
// };

export const getSearchHistory = (params = {}) => (dispatch) => {
    const previousSearchData = History.getPreviousState('globalSearchData', {useUrlParams: true});
    const searchData = {...previousSearchData, ...params};

    const searchParams = ['location', 'city', 'query', 'isOverview', 'center', 'bbox', 'coordinates'];

    if (Utils.isPresent(previousSearchData) && Utils.isPresent(Utils.omit(searchData, ['trade', 'locale', 'new_lang', 'selectedTypes', 'ensure_validity', 'commit'])) && Object.keys(searchData)
        .filter((value) => searchParams.includes(value)).length) {
        dispatch(fetchSearch(searchData, false));
    }
};

export const searchOnHistoryChange = () => (dispatch) => {
    History.onStateChange((newState) => {
        if (newState.globalSearchData) {
            dispatch(fetchSearch(newState.globalSearchData, false));
        }
    });
};

export const toggleSearchFilters = (hideFilters) => ({
    type: ActionTypes.SEARCH_FILTERS_TOGGLE,
    hideFilters
});

export const selectItemClick = (selectedItemType, selectedItemId) => {
    return {
        type: ActionTypes.SEARCH_ITEM_CLICK,
        selectedItemType,
        selectedItemId
    };
};

export const selectItemOver = (selectedItemType, selectedItemId) => {
    return {
        type: ActionTypes.SEARCH_ITEM_OVER,
        selectedItemType,
        selectedItemId
    };
};

export const selectItemOut = (selectedItemType, selectedItemId) => {
    return {
        type: ActionTypes.SEARCH_ITEM_OUT,
        selectedItemType,
        selectedItemId,
        cancelSelection: true
    };
};

// Nearby elements
const receiveAroundMe = (json) => ({
    type: ActionTypes.SEARCH_AROUND_ME_SUCCESS,
    rides: json?.rides || [],
    shops: json?.shops || []
});
export const fetchAroundMe = (selectedTypes, latitude, longitude, limit = 30) => (dispatch) => {
    return api
        .get('/api/search/around', {
            force_locale: window.locale,
            search: {
                selectedTypes,
                query: '*',
                location: {center: [latitude, longitude]},
                limit
            }
        })
        .request
        .then((json) => dispatch(receiveAroundMe(json)));
};

// Related elements
const receiveRelated = (json) => ({
    type: ActionTypes.SEARCH_RELATED_SUCCESS,
    rides: json.rides || [],
    shops: json.shops || [],
    products: json.products || []
});
export const fetchRelated = (selectedTypes, modelId, similitudes, limit = 6) => (dispatch) => {
    return api
        .get('/api/search/related', {
            force_locale: window.locale,
            search: {
                selectedTypes,
                query: '*',
                similitudes,
                modelId,
                limit
            }
        })
        .request
        .then((json) => dispatch(receiveRelated(json)));
};

// Popular elements
const receivePopulars = (selectedType, json) => ({
    type: ActionTypes.SEARCH_POPULAR_SUCCESS,
    popularType: selectedType,
    popularCommon: json?.common,
    rides: json?.rides || [],
    shops: json?.shops || [],
    products: json?.products || [],
    places: json?.places || []
});
export const fetchPopulars = (selectedType, limit, isTypical = false, isHomePage = false) => (dispatch) => {
    return api
        .get('/api/search/populars', {
            force_locale: window.locale,
            search: {
                selectedTypes: [selectedType],
                limit,
                typical: isTypical,
                homePage: isHomePage
            }
        })
        .request
        .then((json) => dispatch(receivePopulars(selectedType, json)));
};

// Affiliated elements
const receiveAffiliations = (json) => ({
    type: ActionTypes.SEARCH_AFFILIATION_SUCCESS,
    affiliations: json.affiliations
});
export const fetchAffiliations = () => (dispatch) => {
    return api
        .get('/api/search/affiliations', {
            force_locale: window.locale
        })
        .request
        .then((json) => dispatch(receiveAffiliations(json)));
};

// Meta search
const receiveMetaSearch = (query, json) => ({
    type: ActionTypes.SEARCH_META_SUCCESS,
    isSearching: false,
    query,
    metaResults: json
});

export const fetchMetaSearch = (query) => (dispatch) => {
    dispatch(initSearch());

    return api
        .get('/api/search/meta', {
            force_locale: window.locale,
            search: {
                query
            }
        })
        .request
        .then((json) => dispatch(receiveMetaSearch(query, json)));
};
